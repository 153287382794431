"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.colorToken = void 0;

function designToken(type, name) {
  return "var(--".concat(type, "-").concat(name, ")");
}

var colorToken = designToken.bind(null, "color");
exports.colorToken = colorToken;