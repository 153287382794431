"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.mergeSpecificKeys = mergeSpecificKeys;

function mergeSpecificKeys(defaults, overrides, keys) {
  return keys.reduce(function (acc, colorType) {
    if (overrides[colorType]) {
      acc[colorType] = overrides[colorType];
    }

    return acc;
  }, defaults);
}