"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("./style.scss");

var _iconbutton = _interopRequireDefault(require("./iconbutton.js"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var _default = _iconbutton.default;
exports.default = _default;