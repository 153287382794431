"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = require("react");

var _excluded = ["forcehover", "forcefocus", "forceactive", "fullwidth", "disabled", "pressed", "error"];

function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

/*---------------------------------------------*/
var COMMON_PROPS = ["hover", "focus", "active", "fullwidth", "disabled", "pressed", "loading"];

function useClassBuilder(props, baseClass) {
  var customClasses = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
  var customProps = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : [];
  var classProps = [].concat(_toConsumableArray(customProps), COMMON_PROPS);
  var dependencies = Object.entries(props).filter(function (_ref) {
    var _ref2 = _slicedToArray(_ref, 1),
        prop = _ref2[0];

    return classProps.includes(prop);
  }).map(function (_ref3) {
    var _ref4 = _slicedToArray(_ref3, 2),
        _ = _ref4[0],
        value = _ref4[1];

    return value;
  }).concat([customClasses]);
  return (0, _react.useMemo)(function () {
    var forcehover = props.forcehover,
        forcefocus = props.forcefocus,
        forceactive = props.forceactive,
        fullwidth = props.fullwidth,
        disabled = props.disabled,
        pressed = props.pressed,
        error = props.error,
        restProps = _objectWithoutProperties(props, _excluded);

    var classes = [baseClass].concat(_toConsumableArray(customClasses));
    COMMON_PROPS.forEach(function (propName) {
      if (props[propName]) {
        classes.push("lth-is-".concat(propName));
      }
    }); // disabled component can not have an error [Efrat]

    error && !disabled && classes.push("lth-has-error"); // allow adding custom className through props

    props.className && classes.push(props.className);
    customProps.filter(function (modifierProp) {
      return typeof restProps[modifierProp] === "boolean" ? restProps[modifierProp] : true;
    }).map(function (modifierProp) {
      var modifierValue = restProps[modifierProp];
      var suffix = typeof modifierValue === "boolean" ? modifierProp : modifierValue;
      return "".concat(baseClass, "--").concat(suffix);
    }).forEach(function (className) {
      classes.push(className);
    });
    return classes.join(" ");
  }, dependencies);
}

var _default = useClassBuilder;
exports.default = _default;