"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = useComponentStyling;

var _useStyleBuilder = _interopRequireDefault(require("./useStyleBuilder"));

var _useClassBuilder = _interopRequireDefault(require("./useClassBuilder"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function useComponentStyling() {
  var baseClass = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "";
  var props = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var modifiers = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
  var customClasses = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : [];
  return {
    classNames: (0, _useClassBuilder.default)(props, baseClass, customClasses, modifiers),
    style: (0, _useStyleBuilder.default)(props)
  };
}