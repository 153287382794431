"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = require("react");

/*---------------------------------------------*/
function useRandomBuilder(props, customStyle) {
  return (0, _react.useMemo)(function () {
    return Math.floor(Math.random() * 10000);
  }, []);
}
/*---------------------------------------------*/


var _default = useRandomBuilder;
exports.default = _default;