"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "Toast", {
  enumerable: true,
  get: function get() {
    return _toast.default;
  }
});
Object.defineProperty(exports, "Toaster", {
  enumerable: true,
  get: function get() {
    return _toaster.default;
  }
});

require("./style.scss");

var _toast = _interopRequireDefault(require("./toast.js"));

var _toaster = _interopRequireDefault(require("./toaster.js"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }